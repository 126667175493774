import gql from 'graphql-tag';

const overallRating = gql`
  query ReviewsScore {
    totalReviewScore {
      rating
    }
  }
`;

export default overallRating;
