import React from 'react';
import PropTypes from 'prop-types';
import { Box, Text, Paragraph } from 'grommet';
import styled from 'styled-components';

import StarRating from './StarRating';

const QuoteContainer = styled(Box)`
  align-items: center;
  text-align: center;

  @media only screen ${(p) =>
      p.theme.global.breakpoints.small.value &&
      `and (max-width: ${p.theme.global.breakpoints.small.value}px)`} {
    align-items: flex-start;
    text-align: left;
    padding: 0;
  }
`;

const ReviewQuote = ({
  rating,
  excerpt,
  city,
  state,
  first_name,
  comments,
  title,
}) => (
  <QuoteContainer gap="medium">
    <StarRating rating={rating} />
    <Paragraph>{excerpt || comments || title}</Paragraph>
    <div>
      <Text weight={500} size="small">
        {first_name}
      </Text>
      <Text size="small">
        , {city}, {state}
      </Text>
    </div>
  </QuoteContainer>
);

ReviewQuote.propTypes = {
  rating: PropTypes.number.isRequired,
  excerpt: PropTypes.string,
  comments: PropTypes.string,
  title: PropTypes.string,
  city: PropTypes.string,
  state: PropTypes.string,
  first_name: PropTypes.string,
};

export default ReviewQuote;
