import React from 'react';
import PropTypes from 'prop-types';
import { Box, Heading, Button } from 'grommet';
import styled from 'styled-components';

import ContentSlider from '../ContentSlider';
import ReviewQuote from './ReviewQuote';
import OverallRating from './OverallRating';
import SmartLink from '../SmartLink';

const QuoteContainer = styled(Box)`
  @media only screen ${(p) =>
      p.theme.global.breakpoints.small.value &&
      `and (max-width: ${p.theme.global.breakpoints.small.value}px)`} {
    padding-left: 20px;
    padding-right: 20px;
  }
`;

const CtaContainer = styled(Box)`
  align-items: center;

  @media only screen ${(p) =>
      p.theme.global.breakpoints.small.value &&
      `and (max-width: ${p.theme.global.breakpoints.small.value}px)`} {
    align-items: flex-start;
    padding-left: 20px;
    padding-right: 20px;
  }
`;

const StyledHeading = styled(Heading)`
  text-align: center;

  @media only screen ${(p) =>
      p.theme.global.breakpoints.small.value &&
      `and (max-width: ${p.theme.global.breakpoints.small.value}px)`} {
    text-align: left;
  }
`;

const sliderConfig = {
  centeredSlides: false,
  slidesPerView: 3,
  autoHeight: true,
  spaceBetween: 0,
  navigation: true,
  breakpoints: {
    1024: {
      slidesPerView: 3,
      spaceBetween: 0,
      centeredSlides: false,
    },
    800: {
      slidesPerView: 3,
      spaceBetween: 14,
      centeredSlides: false,
    },
    320: {
      slidesPerView: 1,
      spaceBetween: 40,
      centeredSlides: true,
    },
  },
};

const Cta = () => (
  <CtaContainer pad={{ vertical: 'medium', horizontal: 'xlarge' }} gap="medium">
    <OverallRating plain />
    <StyledHeading level={4}>
      Don&apos;t take our word for it, take theirs.
    </StyledHeading>
    <SmartLink to="/pages/reviews">
      <Button label="See More Reviews" primary size="small" />
    </SmartLink>
  </CtaContainer>
);

const SliderReviewQuotes = ({ reviews = [] }) => {
  return (
    <Box fill="horizontal">
      <ContentSlider
        params={sliderConfig}
        navColor="000000"
        swiperStyle={{ width: '100%' }}
      >
        {[...reviews, { id: 'cta' }].map((x) =>
          x.id === 'cta' ? (
            <Cta key="cta" />
          ) : (
            <QuoteContainer
              key={x.id}
              pad={{ vertical: 'medium', horizontal: 'xlarge' }}
            >
              <ReviewQuote {...x} />
            </QuoteContainer>
          )
        )}
      </ContentSlider>
    </Box>
  );
};

SliderReviewQuotes.propTypes = {
  loading: PropTypes.bool,
  reviews: PropTypes.array,
};

export default SliderReviewQuotes;
